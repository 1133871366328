import {Pages} from "./Pages";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import "jquery-validation/dist/localization/messages_ru";
import React   from "react";
import {Utils} from "../Utils";

const axios = require('axios').default;

export class ThemesEdit extends Pages {

    constructor(params) {
        super(params);
        this.loadData();
    }

    initPageHandlers(params) {
        super.initPageHandlers(params);

        $("#addThemeForm").validate(
            {
                rules: {
                    "lessonT": {
                        required: true,
                    },
                    "lessonD": {
                        required: true,
                    },
                    "lessonH": {
                        required: true,
                        number: true,
                    },
                },
            },
        );

    }

    setDatepicker(data) {
        $("#lessonD")
            .datepicker({
                dateFormat: app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
                defaultDate: "0",
                datMin: getDate(data.date_start),
                datMax: getDate(data.date_end),
                changeMonth: true,
                changeYear: true,
                numberOfMonths: 3,
                beforeShow: Utils.correctInputPosition,
            });

        function getDate(element) {
            try {
                return $.datepicker.parseDate(
                    app.config.DEFAULT_DATEPICKER_DATE_FORMAT,
                    element.value,
                );
            } catch (error) {
                return null;
            }
        }

    }

    loadData() {

        const gID = $("#gID").val();

        axios.get(`/api/groups/${gID}/`)
             .then((response) => {
                 this.setDatepicker(response.data.data);
             })
             .catch((error) => {
                 throw Error(`Не удалось загрузить данные! Ошибка: ${error}`);
             });

    }

}
